import React,{Component} from 'react'
import './App.css';
import './style.css';
import './index.css';
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer'
import Jumbotron from './components/Jumbotron'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import Contact from './pages/Contact'

import Product_catalog from './pages/Product_catalog'

import Home from './pages/Home'
import About from './pages/About'
import Error404 from './pages/Error404'
import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import MediaQuery from 'react-responsive'
class App extends Component {
  render() {
    if(!(localStorage.getItem('lang'))){
      localStorage.setItem('lang', 'en')
    }
  const store = createStore(reducer, applyMiddleware(thunk));
  return (
    <>
      <BrowserRouter>
      <MediaQuery minDeviceWidth={992}>
        <div class="body" style={{marginRight:"6.5vw", marginLeft:"6.5vw"}}>
        <Jumbotron/>
          <Provider store={store}>
              <Navbar/>

              <Switch>
                  <Route exact path="/contact" component={Contact}/>
                  <Route exact path="/" component={Home}/>
                  <Route exact path="/about" component={About}/>
                  <Route exact path="/product/:id" component={Product_catalog}/>

                  <Route component={Error404} />
                </Switch>
          <Footer/>
          </Provider>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991}>
        <div class="body">
          <Provider store={store}>
              <Navbar/>
              <Switch>
                  <Route exact path="/contact" component={Contact}/>
                  <Route exact path="/" component={Home}/>
                  <Route exact path="/about" component={About}/>
                  <Route exact path="/product/:id" component={Product_catalog}/>

                  <Route component={Error404} />
                </Switch>
          <Footer/>
          </Provider>

        </div>
      </MediaQuery>
      </BrowserRouter>
    </>
  );
}
}

export default App;
