import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style.css';
import Aos from "aos";
import "aos/dist/aos.css"
import MediaQuery from 'react-responsive'
const Ourpartners = (props)=>{
  let language = props.language
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
  return (
    <>
  <div className="container body">
    <div class="row" data-aos="fade-up">
    <div class="col" style={{marginTop:"50px"}}>
    <center>
    <MediaQuery minDeviceWidth={992}>
      {language === 'th' ?
      <>
      <p style={{fontSize:"3vw", color:"gray"}}>คู่ค้าของเรา</p>
      <p style={{fontSize:"2vw"}}></p>
      </>
      :
      <>
      <p style={{fontSize:"3vw", color:"gray"}}>OUR PARTNERS</p>
      <p style={{fontSize:"2vw"}}></p>
      </>
      }
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/abp.jpg"} alt="ABP" style={{width:"15vw", marginRight:"30px"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/Bosch.png"} alt="Bosch" style={{width:"20vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/exensor.png"} alt="Exensor" style={{width:"20vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/senstar.png"} alt="Senstar" style={{width:"20vw"}}/><br/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/TAR.png"} alt="TAR" style={{width:"15vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/verint.png"} alt="Verint" style={{width:"20vw"}}/>
    </MediaQuery>

    <MediaQuery maxDeviceWidth={991}>
      {language === 'th' ?
      <>
      <p style={{fontSize:"10vw", color:"gray"}}>คู่ค้าของเรา</p>
      </>
      :
      <>
      <p style={{fontSize:"10vw", color:"gray"}}>OUR PARTNERS</p>
      </>
      }
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/abp.jpg"} alt="ABP" style={{width:"30vw", marginRight:"10px"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/Bosch.png"} alt="Bosch" style={{width:"35vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/exensor.png"} alt="Exensor" style={{width:"40vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/senstar.png"} alt="Senstar" style={{width:"40vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/TAR.png"} alt="TAR" style={{width:"25vw"}}/>
      <img data-aos="zoom-in" src={process.env.PUBLIC_URL+"/image/partners/verint.png"} alt="Verint" style={{width:"40vw"}}/>
    </MediaQuery>
    </center>
    </div>
    </div>
  </div>
    </>

  );
}
export default Ourpartners;
