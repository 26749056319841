import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style.css';
import Aos from "aos";
import "aos/dist/aos.css"
import MediaQuery from 'react-responsive'
const Welcome = (props)=>{
  let language = props.language
  
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
  return (
    <>
    <div class="container-image" data-aos="fade-up" style={{marginTop:"30px"}}>
    <MediaQuery minDeviceWidth={992}>
      <img data-aos="fade-up" src={process.env.PUBLIC_URL+"/image/cyper.gif"} alt="Snow" style={{width:"100%", height:"100vh"}}/>
      <div class="centered" style={{fontSize:"40px"}}>{language === 'th' ?<><p style={{fontSize:"3vw"}}>บริษัทกันโน่ ซีสเต็มส์ <br/>อินทีเกรชั่น จำกัด <br/>ยินดีต้อนรับ</p>
        <p style={{fontSize:"2vw"}}>ความพึงพอใจของคุณ <br/>คือสิ่งสำคัญ</p></>:<><p style={{fontSize:"3vw"}}>WELCOME <br/>TO GUNNO</p>
        <p style={{fontSize:"2vw"}}>Your satisfaction is what <br/>significant.</p></>}
      </div>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={991}>
      <img data-aos="fade-up" src={process.env.PUBLIC_URL+"/image/cyper.gif"} alt="Snow" style={{width:"100%", height:"50vh"}}/>
      <div class="centered" style={{fontSize:"4.5vw"}}>{language === 'th' ?
      <><p style={{fontSize:"5.7vw"}}>บริษัทกันโน่ ซีสเต็มส์ <br/>อินทีเกรชั่น จำกัด <br/>ยินดีต้อนรับ</p>
        ความพึงพอใจของคุณ <br/>คือสิ่งสำคัญ</>:
        <><p style={{fontSize:"5.7vw"}}>WELCOME <br/>TO GUNNO</p>
        Your satisfaction is what significant.</>}
      </div>
    </MediaQuery>
    </div>
    </>

  );
}
export default Welcome;
