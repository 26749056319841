import axios from 'axios'

//ดึงข้อมูลของตาราง Contact มาใช้งาน


export const getProduct=(lang)=>{
  return (dispatch)=>{
    axios.get('http://34.126.81.222:9000/product'
    ,{
      params:{
        lang:lang
      }
    })
    .then((res)=>{
      dispatch({
        type: 'GET_PRODUCT',
        playload:res.data
      })
    })
  }
}

//http://35.240.212.253:9000/product/${catalog}'
export const getProductList = (catalog,lang) =>{
  return (dispatch) => {
    axios.get('http://34.126.81.222:9000/product/${catalog}'
    ,{
      params:{
        id:catalog,
        lang:lang
      }
    })
    .then((res)=>{
      dispatch({
        type: 'GET_PRODUCTLIST',
        playload:res.data
      })
    })
  }
}
