import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style.css';
import Aos from "aos";
import "aos/dist/aos.css"
import MediaQuery from 'react-responsive'
const Jumbotron = (props)=>{
  const data = props.data;
  const img = props.img
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
  return (
    <>
    </>
  )
}
export default Jumbotron;
