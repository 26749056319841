import React, { Component } from 'react'
class Error404 extends Component{
    render(){
      return(<div style={{top:"50%",left: "50%", position:"fixed", marginLeft:"-100px", marginTop:"-50px"}}><center><h1>Error 404 Not Found</h1></center></div>)
    }


}

export default Error404;
