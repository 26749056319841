import React, { Component } from 'react'

import { connect } from 'react-redux'
import * as action from '../action'
import '../style.css';
import MediaQuery from 'react-responsive'

class Catalogs extends Component{
  render(){

    const allProductCatalog = this.props.productFromStore;

    let language = localStorage.getItem('lang')
    let list_catalogs = []
    if(allProductCatalog.length !==0){
allProductCatalog.map(function(item){
  list_catalogs.push(
        <>
      <MediaQuery minDeviceWidth={992}>
      <div class="col-2 text-center" data-aos="zoom-in" style={{marginRight:"2.5vw", marginTop:"3vw", marginLeft:"2.5vw"}}>
          <img class img-fluid src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"30vh"}} class="rounded-circle" />
          <p style={{fontSize:"1.6vw"}}>
          {item.catalog_name}
          </p>
      </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991.99}>
      <div class="col-4 text-center" data-aos="zoom-in" style={{marginRight:"0", marginTop:"3vw", marginLeft:"0"}}>
          <img class img-fluid src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"10vh"}} class="rounded-circle" />
          <p style={{fontSize:"2.9vw"}}>
          {item.catalog_name}
          </p>
      </div>
      </MediaQuery>

      </>
)
      }
    );
    }
    return(
      <>
      <MediaQuery minDeviceWidth={992}>
      <div class="row justify-content-center">
        {list_catalogs}
      </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991.99}>
        {list_catalogs}
      </MediaQuery>
{/* ---------------------------------------------------------------------มุมมองมือถือ------------------------------------------------------------------*/}
      </>


    )
  }
  componentDidMount(){
    this.props.getallProductCatalog();
  }
}
const mapStateToProps =  state =>{
  return {
    productFromStore : state.catalogs
  }
}
const mapDispatchToProps = dispatch =>{
  const lang = localStorage.getItem('lang')||'en';
  return {
    getallProductCatalog : () =>{
      return dispatch(action.getProduct(lang))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Catalogs);
