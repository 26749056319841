import React, { Component, useEffect} from 'react'
import MediaQuery from 'react-responsive'
import Aos from "aos";
import "aos/dist/aos.css"
import ContactInput from '../components/ContactInput'
import '../style.css';
const Contact = (props)=>{
    let language = localStorage.getItem('lang')
    useEffect(() => {
      Aos.init({duration: 1000});
    }, [])
    return(
      <div style={{paddingTop:"90px"}}>
      <>
      <MediaQuery minDeviceWidth={992}>
      <div class="container-fluid" data-aos="fade-up" style={{marginTop:"50px", width:"80%"}}>
        <div class="row">

          <div class="col">
            <p style={{fontSize:"6vw", color:"gray"}}>
            {language === "th"?
            <>ติดต่อเรา</>:
            <>CONTACT US</>}
            </p>
            <ContactInput token = "jbb3qt3ukjQ9taIAy4A0Frg4qqHiIBioLZrLVXUFVEj" language={language}/>
          </div>
        </div>
        <div class="container" data-aos="fade-up" style={{marginTop:"50px"}}>
        <iframe class="responsive" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.3061595932777!2d100.58881251398098!3d13.880632998036702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e282c5f896a23f%3A0xcc46f9ebac9ed4c0!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4geC4seC4meC5guC4meC5iCDguIvguLXguKrguYDguJXguYfguKHguKrguYwg4Lit4Li04LiZ4LiX4Li14LmA4LiB4Lij4LiK4Lix4LmI4LiZIOC4iOC4s-C4geC4seC4lCA6OiBHdW5ubyBTeXN0ZW1zIEludGVncmF0aW9uIENvLixMdGQu!5e0!3m2!1sth!2sth!4v1592297690272!5m2!1sth!2sth"
        width="100%" height="500px" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
        </div>
      </div>
      </MediaQuery>


      <MediaQuery maxDeviceWidth={991}>
      <div class="container-fluid" data-aos="fade-up" style={{marginTop:"50px", width:"100%"}}>
        <div class="row">

          <div class="col">
            <p style={{fontSize:"10vw", color:"gray"}}>
            <center>
            {language === "th"?
            <>ติดต่อเรา</>:
            <>CONTACT US</>}

            </center>
            </p>
            <ContactInput token = "jbb3qt3ukjQ9taIAy4A0Frg4qqHiIBioLZrLVXUFVEj" language={language}/>
          </div>
        </div>
        <div class="container" data-aos="fade-up" style={{marginTop:"50px"}}>
        <iframe class="responsive" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.3061595932777!2d100.58881251398098!3d13.880632998036702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e282c5f896a23f%3A0xcc46f9ebac9ed4c0!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4geC4seC4meC5guC4meC5iCDguIvguLXguKrguYDguJXguYfguKHguKrguYwg4Lit4Li04LiZ4LiX4Li14LmA4LiB4Lij4LiK4Lix4LmI4LiZIOC4iOC4s-C4geC4seC4lCA6OiBHdW5ubyBTeXN0ZW1zIEludGVncmF0aW9uIENvLixMdGQu!5e0!3m2!1sth!2sth!4v1592297690272!5m2!1sth!2sth"
        width="100%" height="500px" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
        </div>
      </div>
      </MediaQuery>
      </>
      </div>
    )

}


export default Contact;
