import React, { Component } from 'react';
import MediaQuery from 'react-responsive'
import axios from 'axios'


class ContactInputForm extends Component{
  constructor(props) {
    super(props);
    const token = props.token
    let language = props.language
  }

  handleValidation(){
    let data = this.state
    let errors = {};
    let formIsValid = true;
    if(!data["name"]){
      formIsValid = false;
      errors["name"] = true
    }

    if(!data["phone"]){
      formIsValid = false;
      errors["phone"] = true
    }
    if(!data["email"]){
      formIsValid = false;
      errors["email"] = true
    }
    if(!data["subject"]){
      formIsValid = false;
      errors["subject"] = true
    }
    if(!data["message"]){
      formIsValid = false;
      errors["message"] = true
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  state = {
    errors:{},
    name:'',
    phone:'',
    email:'',
    subject:'',
    message:'',
    token:this.props.token

  }
  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
      console.log(this.state);
  }
  onLoginSubmit = (event) =>{
    event.preventDefault();
    if(this.handleValidation()){

      console.log(this.state);
      axios.post('http://34.126.81.222:9000/contact_message', this.state)
        .then(response =>{

          console.log(response)
          alert("Send Message Success");


        })
        .catch(error =>{
          console.log(error)
        })
    }

  }
render(){
  const {name, phone, email, subject, message } = this.state
  let language = this.props.language
  let text_lang = {}
  text_lang["th"] = ['ชื่อ', 'เบอร์โทรศัพท์', 'อีเมล', 'ชื่อเรื่อง', 'กรอกข้อความของคุณ', 'ส่งข้อความ']
  text_lang["en"] = ['Name', 'Phone Number', 'Email', 'Subject', 'Type your message here...', 'Send Message']
  return (
    <>


    <MediaQuery minDeviceWidth={992}>
    <div className="container body">
            <form onSubmit={this.onLoginSubmit}>
              <div className="row">
                <div className="col">
                {this.state.errors["name"] ?
                <>
                <input type="text"
                className = "form-control is-invalid no-border"
                placeholder={text_lang[language][0]}
                 id="name"
                 name="name"
                 value={name}
                 onChange={this.onInputChange}/>
                 <div class="invalid-feedback">Cannot be empty</div>
                 </>
                 :
                <input type="text"
                className = "form-control no-border"
                placeholder={text_lang[language][0]}

                 id="name"
                 name="name"
                 value={name}
                 onChange={this.onInputChange}/>}

                </div>
                <div className="col">
                {this.state.errors["phone"] ?
                  <>
                  <input type="text" class="form-control is-invalid no-border"
                  placeholder={text_lang[language][1]}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={this.onInputChange}/>
                  <div class="invalid-feedback">Cannot be empty</div>
                  </>
                  :
                  <input type="text" class="form-control no-border"
                  placeholder={text_lang[language][1]}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={this.onInputChange}/>
                }
                </div>
                </div>


                <div className="row" style={{marginTop:"10px"}}>
                <div className="col">
                {this.state.errors["phone"] ?
                  <>
                  <input type="email" class="form-control is-invalid no-border"
                  placeholder={text_lang[language][2]}
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onInputChange}/>
                  <div class="invalid-feedback">Cannot be empty</div>
                  </>
                  :
                  <input type="email" class="form-control no-border"
                  placeholder={text_lang[language][2]}
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onInputChange}/>
                }
                </div>
                <div className="col" >
                {this.state.errors["subject"] ?
                  <>
                  <input type="text" class="form-control is-invalid no-border"
                  placeholder={text_lang[language][3]}
                  id="subject"
                  name="subject"
                  value={subject}
                  onChange={this.onInputChange}/>
                  <div class="invalid-feedback">Cannot be empty</div>
                  </>
                  :
                  <input type="text" class="form-control no-border"
                  placeholder={text_lang[language][3]}
                  id="subject"
                  name="subject"
                  value={subject}
                  onChange={this.onInputChange}/>
                }
                </div>
                </div>
                <div className="col-13" style={{marginTop:"10px"}}>
                {this.state.errors["message"] ?
                  <>
                  <textarea type="text-area" class="form-control is-invalid no-border"
                  placeholder={text_lang[language][4]}
                  id="message"
                  name="message"
                  value={message}
                  onChange={this.onInputChange}/>
                  <div class="invalid-feedback">Cannot be empty</div>
                  </>
                  :
                  <textarea type="text-area" class="form-control no-border"
                  placeholder={text_lang[language][4]}
                  id="message"
                  name="message"
                  value={message}
                  onChange={this.onInputChange}/>
                }
                </div>
                <br/>
                <input type="submit" style={{width:"100%"}} class="btn btn-dark" value={text_lang[language][5]}/>
            </form>
        </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={992}>
              <div className="body">
                <form onSubmit={this.onLoginSubmit}>
                  <div className="row">
                    <div className="col">
                    {this.state.errors["name"] ?
                    <>
                    <input type="text"
                    className = "form-control is-invalid no-border"
                    placeholder={text_lang[language][0]}
                     id="name"
                     name="name"
                     value={name}
                     onChange={this.onInputChange}/>
                     <div class="invalid-feedback">Cannot be empty</div>
                     </>
                     :
                    <input type="text"
                    className = "form-control no-border"
                    placeholder={text_lang[language][0]}

                     id="name"
                     name="name"
                     value={name}
                     onChange={this.onInputChange}/>}

                    </div>
                    <div className="col">
                    {this.state.errors["phone"] ?
                      <>
                      <input type="text" class="form-control is-invalid no-border"
                      placeholder={text_lang[language][1]}
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={this.onInputChange}/>
                      <div class="invalid-feedback">Cannot be empty</div>
                      </>
                      :
                      <input type="text" class="form-control no-border"
                      placeholder={text_lang[language][1]}
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={this.onInputChange}/>
                    }
                    </div>
                    </div>


                    <div className="row" style={{marginTop:"10px"}}>
                    <div className="col">
                    {this.state.errors["email"] ?
                      <>
                      <input type="email" class="form-control is-invalid no-border"
                      placeholder={text_lang[language][2]}
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.onInputChange}/>
                      <div class="invalid-feedback">Cannot be empty</div>
                      </>
                      :
                      <input type="email" class="form-control no-border"
                      placeholder={text_lang[language][2]}
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.onInputChange}/>
                    }
                    </div>
                    <div className="col" >
                    {this.state.errors["subject"] ?
                      <>
                      <input type="text" class="form-control is-invalid no-border"
                      placeholder={text_lang[language][3]}
                      id="subject"
                      name="subject"
                      value={subject}
                      onChange={this.onInputChange}/>
                      <div class="invalid-feedback">Cannot be empty</div>
                      </>
                      :
                      <input type="text" class="form-control no-border"
                      placeholder={text_lang[language][3]}
                      id="subject"
                      name="subject"
                      value={subject}
                      onChange={this.onInputChange}/>
                    }
                    </div>
                    </div>
                    <div className="col-13" style={{marginTop:"10px"}}>
                    {this.state.errors["message"] ?
                      <>
                      <textarea type="text-area" class="form-control is-invalid no-border"
                      placeholder={text_lang[language][4]}
                      id="message"
                      name="message"
                      value={message}
                      onChange={this.onInputChange}/>
                      <div class="invalid-feedback">Cannot be empty</div>
                      </>
                      :
                      <textarea type="text-area" class="form-control no-border"
                      placeholder={text_lang[language][4]}
                      id="message"
                      name="message"
                      value={message}
                      onChange={this.onInputChange}/>
                    }
                    </div>
                    <br/>
                    <input type="submit" style={{width:"100%"}} class="btn btn-dark" value={text_lang[language][5]}/>
                </form>
              </div>
        </MediaQuery>


      </>
  );
}
}


export default ContactInputForm;
