import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style.css';
import Aos from "aos";
import "aos/dist/aos.css"
import MediaQuery from 'react-responsive'
const Vision = (props)=>{
  let language = props.language
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
  return (
    <>
    <div class="container body" data-aos="fade-up">
    <MediaQuery minDeviceWidth={992}>
      <div class="row" style={{height:"50vh", width:"100%"}}>
        <div class="centered" >
          <center>
            {language === 'th' ?
            <><p  data-aos="fade-up" style={{fontSize:"3vw", color:"gray"}}>วิสัยทัศน์ของเรา</p>
            <p data-aos="fade-up" style={{fontSize:"2vw"}}>"ผู้นำเทคโนโลยี ศักยภาพดีเยี่ยม เต็มเปี่ยมคุณธรรม บุคลากรก้าวล้ำ องค์กรก้าวไกล"</p></>
            :
            <><p  data-aos="fade-up" style={{fontSize:"3vw", color:"gray"}}>OUR VISION</p>
            <p data-aos="fade-up" style={{fontSize:"2vw"}}>"Being the best security systems provider and systems integrator is our main aspiration."</p></>}
          </center>
        </div>
      </div>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={991}>
      <div class="row" style={{height:"30vh", width:"100%"}}>
        <div class="centered">
          <center>
            {language === 'th' ?
            <><p  data-aos="fade-up" style={{fontSize:"7vw", color:"gray"}}>วิสัยทัศน์ของเรา</p>
            <p data-aos="fade-up" style={{fontSize:"4.3vw"}}>"ผู้นำเทคโนโลยี ศักยภาพดีเยี่ยม เต็มเปี่ยมคุณธรรม บุคลากรก้าวล้ำ องค์กรก้าวไกล"</p></>
            :
            <><p  data-aos="fade-up" style={{fontSize:"7vw", color:"gray"}}>OUR VISION</p>
            <p data-aos="fade-up" style={{fontSize:"4.3vw"}}>"Being the best security systems provider and systems integrator is our main aspiration."</p></>}
          </center>
        </div>
      </div>
    </MediaQuery>
    </div>
    </>

  );
}
export default Vision;
