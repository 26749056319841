const initialState = {
  catalogs:[],
  products:[]
}
const reducer = (state = initialState, action)=>{
  switch(action.type){

      case 'GET_PRODUCT':
        const allProductState = {
          ...state,
          catalogs: action.playload
        }
        return allProductState;

      case 'GET_PRODUCTLIST':
        const allProductListState={
          ...state,
          products: action.playload
        }
        return allProductListState;

      default:
        break;
  }
  return state;
}
export default reducer;
