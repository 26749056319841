import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'

import * as action from '../action'
import Aos from "aos";
import "aos/dist/aos.css"
import '../style.css';
// import Catalog_navbar from '../components/Navbar/Navbar_catalog'
import MediaQuery from 'react-responsive'
class ProductInCatalog extends Component{
  constructor(props) {
    super(props);
    const id = props.id
    let language = props.language
  }
  state = {
  catalogs:{},
  id:1
  }
  render(){
    let language = localStorage.getItem('lang')
    const allProductList = this.props.productlistFromStore;
    let catalog_id =  this.props.id
    let count = 0
    let first_row;
    let catalog = {}

    if(allProductList.length !==0){
    allProductList.map(function(item){
    count++;
    if(item.catalog_id in catalog){
      catalog[item.catalog_id].push(
        <>
        <MediaQuery minDeviceWidth={992}>
        <div class="col-5" style={{paddingRight:"1.5vw"}}>
          <div  class="container-image" data-aos="fade-up" style={{paddingTop:"1.5vw"}}>
            <img class img-fluid src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.product_name} style={{width:"100%", height:"50vh"}}/>
            <div class="centered" style={{fontSize:"3vw", background: "rgb(0 0 0 / 67%)"}}><p data-aos="fade-up" style={{marginTop:"10px"}}>{item.product_name}</p></div>
          </div>
        </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991.99}>
        <div class="col-11" style={{paddingRight:"1.5vw"}}>
          <div  class="container-image" data-aos="fade-up" style={{paddingTop:"1.5vw"}}>
          <img src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.product_name}style={{width:"100%", height:"40vh"}}/>
          <div class="centered" style={{fontSize:"7vw", background: "rgb(0 0 0 / 67%)"}}><p data-aos="fade-up" style={{marginTop:"20px"}}>{item.product_name}</p></div>
          </div>
        </div>
        </MediaQuery>
        </>
      )
    }
    else{
      catalog[item.catalog_id] = []
      catalog[item.catalog_id].push(
        <>
        <MediaQuery minDeviceWidth={992}>
          <div class="col-5" style={{paddingRight:"1.5vw"}}>
            <div  class="container-image" data-aos="fade-up" style={{paddingTop:"1.5vw"}}>
              <img class img-fluid src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.product_name} style={{width:"100%", height:"50vh"}}/>
              <div class="centered" style={{fontSize:"3vw", background: "rgb(0 0 0 / 67%)"}}><p data-aos="fade-up" style={{marginTop:"10px"}}>{item.product_name}</p></div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={991.99}>
          <div class="col-11" style={{paddingRight:"1.5vw"}}>
            <div  class="container-image" data-aos="fade-up" style={{paddingTop:"1.5vw"}}>
            <img src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.product_name}style={{width:"100%", height:"40vh"}}/>
            <div class="centered" style={{fontSize:"7vw", background: "rgb(0 0 0 / 67%)"}}><p data-aos="fade-up" style={{marginTop:"20px"}}>{item.product_name}</p></div>
            </div>
          </div>
        </MediaQuery>
        </>
      )
    }

    }
  );
}

    return(
      <>
      <div class="row justify-content-center">
        {catalog[catalog_id]}
      </div>
      </>
    )
  }
  componentDidMount(){
    this.props.getallProductList();


  }
}
const mapStateToProps =  state =>{
  return {
    productlistFromStore : state.products
  }
}
const mapDispatchToProps = (dispatch, ownProps) =>{
  const lang = localStorage.getItem('lang')||'en';

  return {
    getallProductList : () =>{
      return dispatch(action.getProductList(ownProps.id, lang))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductInCatalog);
