import React, { Component } from 'react'

import { connect } from 'react-redux'
import * as action from '../action'
import '../style.css';
import MediaQuery from 'react-responsive'
import ContactInput from '../components/ContactInput'
import Welcome from '../components/Welcome'
import Vision from '../components/Vision'
import Catalogs from '../components/Catalogs'
import Ourpartners from '../components/Ourpartners'
class Home extends Component{
  render(){

    const allProductCatalog = this.props.productFromStore;

    let language = localStorage.getItem('lang')
    let text_lang = {}
    text_lang["th"] = ['ติดต่อเรา']
    text_lang["en"] = ['CONTACT US']
    let count = 0
    let carousel = []
    if(allProductCatalog.length !==0){
    allProductCatalog.map(function(item){
        count++;
        if(count === 1){
          carousel.push(
            <div class="carousel-item active">
            <MediaQuery minDeviceWidth={992}>
              <img class="d-block" src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"100vh"}}/>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={991}>
              <img class="d-block" src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"50vh"}}/>
            </MediaQuery>
              <div class="centered" style={{fontSize:"40px"}}>
              <MediaQuery minDeviceWidth={992}>
                    <p data-aos="zoom-in" style={{fontSize:"6vw", color:"white", background: "rgb(0 0 0 / 67%)"}}>{item.catalog_name}</p>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={991}>
                    <p data-aos="zoom-in" style={{fontSize:"8vw", color:"white", background: "rgb(0 0 0 / 67%)"}}>{item.catalog_name}</p>
              </MediaQuery>
              </div>
            </div>
          )


        }
        else{
          carousel.push(
            <>

            <div class="carousel-item">
            <MediaQuery minDeviceWidth={992}>
            <img class="d-block" src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"100vh"}}/>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={991}>
            <img class="d-block" src={process.env.PUBLIC_URL+"/image/"+item.image} alt={item.catalog_name} style={{width:"100%", height:"50vh"}}/>
            </MediaQuery>
            <div class="centered" style={{fontSize:"40px"}}>
            <MediaQuery minDeviceWidth={992}>
                  <p data-aos="zoom-in" style={{fontSize:"6vw", color:"white", background: "rgb(0 0 0 / 67%)"}}>{item.catalog_name}</p>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={991}>
                  <p data-aos="zoom-in" style={{fontSize:"8vw", color:"white", background: "rgb(0 0 0 / 67%)"}}>{item.catalog_name}</p>
            </MediaQuery>
            </div>
            </div>
            </>
          )
        }
        return true

      }
    );
    }
    return(
      <>
  <MediaQuery minDeviceWidth={992}>
      <div class=" body" data-aos="fade-up" style={{marginTop:"90px"}}>
        <Welcome language={language}/>
        <Vision language={language}/>
      <div class="container-image" data-aos="fade-up" style={{marginTop:"30px"}}>
        <img src={process.env.PUBLIC_URL+"/image/preview_cctv.jpg"} alt="preview" style={{width:"100%", height:"100vh"}}/>
      </div>


{/* --------------------------- สินค้าและบริการของเรา --------------------------------*/}

      <div class="row" data-aos="fade-up">
      <div class="col">
      <center>

        {language === 'th' ?
        <>
        <p style={{fontSize:"3vw", color:"gray"}}>บริการของเรา</p>
        <p style={{fontSize:"2vw"}}>เราทำอะไรบ้าง</p>
        </>
        :
        <>
        <p style={{fontSize:"3vw", color:"gray"}}>SERVICES</p>
        <p style={{fontSize:"2vw"}}>What We Do</p>
        </>
        }


{/*
      <div id="carouselExampleIndicators" data-aos="fade-up" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">

        {carousel}

        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

      </div>
      */}
      </center>
      </div>
      </div>
      <div class="row justify-content-center">
                    <Catalogs/>
    </div>
{/* --------------------------- สินค้าและบริการของเรา --------------------------------*/}
    <Ourpartners language={language}/>

{/* ---------------------------ส่วนติดต่อและแผนที่บริษัท--------------------------------*/}
      <div class="container" data-aos="fade-up" style={{marginTop:"70px"}}>
        <div class="row">
          <div class="col" data-aos="fade-right">
          <img src={process.env.PUBLIC_URL+"/image/contact.jpeg"} alt="Snow" style={{width:"100%", height:"100%"}}/>
          </div>
          <div class="col body">
            <p style={{fontSize:"3vw", color:"gray"}}>
              <center>
              {text_lang[language]}
              </center>
            </p>

            <ContactInput token="jbb3qt3ukjQ9taIAy4A0Frg4qqHiIBioLZrLVXUFVEj" language={language}/>
          </div>
        </div>
      </div>
      <div class="container" data-aos="fade-up" style={{marginTop:"30px"}}>
      <iframe class="responsive" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.3061595932777!2d100.58881251398098!3d13.880632998036702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e282c5f896a23f%3A0xcc46f9ebac9ed4c0!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4geC4seC4meC5guC4meC5iCDguIvguLXguKrguYDguJXguYfguKHguKrguYwg4Lit4Li04LiZ4LiX4Li14LmA4LiB4Lij4LiK4Lix4LmI4LiZIOC4iOC4s-C4geC4seC4lCA6OiBHdW5ubyBTeXN0ZW1zIEludGVncmF0aW9uIENvLixMdGQu!5e0!3m2!1sth!2sth!4v1592297690272!5m2!1sth!2sth"
      width="100%" height="500px" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
      </div>
      </div>
{/* ---------------------------ส่วนติดต่อและแผนที่บริษัท--------------------------------*/}
  </MediaQuery>

{/* ---------------------------------------------------------------------มุมมองมือถือ------------------------------------------------------------------*/}
<MediaQuery maxDeviceWidth={991.99}>

  <div class=" body" data-aos="fade-up" style={{marginTop:"90px"}}>
    <Welcome language={language}/>
    <Vision language={language}/>
    <div class="container-image" data-aos="fade-up" style={{marginTop:"30px"}}>
      <img src={process.env.PUBLIC_URL+"/image/preview_cctv.jpg"} alt="preview" style={{width:"100%", height:"50vh"}}/>
    </div>

  {/* --------------------------- สินค้าและบริการของเรา --------------------------------*/}
    <div className="container body">

  <div class="row" data-aos="fade-up">
    <div class="col">
    <center>
      {language === 'th' ?
      <>
      <p style={{fontSize:"9vw", color:"gray"}}>บริการของเรา</p>
      <p style={{fontSize:"6vw"}}>เราทำอะไรบ้าง</p>
      </>
      :
      <>
      <p style={{fontSize:"9vw", color:"gray"}}>SERVICES</p>
      <p style={{fontSize:"6vw"}}>What We Do</p>
      </>
      }
    </center>
    </div>
    </div>


    <div class="row justify-content-center">
                  <Catalogs/>
  </div>
  </div>
    {/* --------------------------- สินค้าและบริการของเรา --------------------------------*/}
    <Ourpartners language={language}/>

    {/* ---------------------------ส่วนติดต่อและแผนที่บริษัท--------------------------------*/}
    <div class="container" data-aos="fade-up" style={{marginTop:"70px"}}>
      <div class="row">
        <div class="col body">
          <p className="body" style={{fontSize:"10vw", color:"gray"}}>
          <center>
          {text_lang[language]}
          </center>
          </p>
          <ContactInput token="jbb3qt3ukjQ9taIAy4A0Frg4qqHiIBioLZrLVXUFVEj" language={language}/>
        </div>
      </div>
    </div>
    <div class="container" data-aos="fade-up" style={{marginTop:"30px"}}>
    <iframe class="responsive" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.3061595932777!2d100.58881251398098!3d13.880632998036702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e282c5f896a23f%3A0xcc46f9ebac9ed4c0!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4geC4seC4meC5guC4meC5iCDguIvguLXguKrguYDguJXguYfguKHguKrguYwg4Lit4Li04LiZ4LiX4Li14LmA4LiB4Lij4LiK4Lix4LmI4LiZIOC4iOC4s-C4geC4seC4lCA6OiBHdW5ubyBTeXN0ZW1zIEludGVncmF0aW9uIENvLixMdGQu!5e0!3m2!1sth!2sth!4v1592297690272!5m2!1sth!2sth"
    width="100%" height="300px" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe><br/>
    </div>
  </div>
</MediaQuery>
{/* ---------------------------------------------------------------------มุมมองมือถือ------------------------------------------------------------------*/}
      </>


    )
  }
  componentDidMount(){
    this.props.getallProductCatalog();
  }
}
const mapStateToProps =  state =>{
  return {
    productFromStore : state.catalogs
  }
}
const mapDispatchToProps = dispatch =>{
  const lang = localStorage.getItem('lang')||'en';
  return {
    getallProductCatalog : () =>{
      return dispatch(action.getProduct(lang))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
