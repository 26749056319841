import React, { Component } from 'react'
import '../../style.css';
import {NavLink } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import MediaQuery from 'react-responsive'
class Navbar extends Component{

  render(){
    const changeLanguage = (language) => {
      if(language){
        localStorage.setItem('lang', language);
      }
      else{
        localStorage.setItem('lang', 'en')
      }
      window.location.reload()
    };

    const ScollToTop = () => {
      window.scrollTo(0, 0);

      };
  if(localStorage.getItem('lang') === 'th'){
    var language = 'th'
  }
  else{
    var language = 'en'
  }
  let text_lang = {}
  let product_lang = {}
  text_lang["th"] = ['บริษัทกันโน่ ซีสเต็มส์ อินทีเกรชั่น จำกัด', 'หน้าหลัก', 'เกี่ยวกับเรา', 'สินค้าและบริการ', 'ติดต่อเรา', 'อังกฤษ', 'ไทย']
  text_lang["en"] = ['Gunno Systems Integration Co.,Ltd.', 'Home', 'About', 'Products&Services', 'Contact Us', "EN", 'TH']
  product_lang["th"] = ["ระบบรักษาความปลอดภัย", "ระบบด้านการติดต่อสื่อสาร", "ระบบด้านการตรวจสอบ", "ระบบด้านความมั่นคงทางทหาร", "ระบบด้านการข่าวและไซเบอร์", "ระบบภาพและเสียง", "ออกแบบและปรับปรุงศูนย์บัญชาการ"]
  product_lang["en"] = ["Security System", "Communication System", "Inspection System", "Defense System", "Intelligence System", "Visual & Audio System", "Renovation & Integration"]
  return (

    <>
    <MediaQuery minDeviceWidth={992}>
    <nav class="navbar navbar-expand-lg navbar-light top-bar fixed-top" style={{marginLeft:"6.5vw", marginRight:"6.5vw"}}>&emsp;
      <NavLink onClick={() => ScollToTop()} exact to='/' style={{color:"black", textDecoration: 'none'}}><img src={process.env.PUBLIC_URL+"/image/logo.png"} alt="Logo GSI" style={{width:"15%"}}/>&emsp;{text_lang[language][0]}</NavLink>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
       aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent" data-toggle="collapse" data-target=".navbar-collapse.show" style={{marginLeft:"2%"}}>
        <ul class="navbar-nav ml-auto">
        <li>
            <NavLink onClick={() => ScollToTop()} exact to='/'className="link-style"activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][1]}</NavLink>
          </li>
          <li>
            <NavLink onClick={() => ScollToTop()} exact to='/about'className="link-style"activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][2]}</NavLink>
          </li>
          <li >
              <div class="dropdown show">
                <NavLink className="link-style dropdown-toggle" activeClassName="link-style-active" exact to ="/product/" role="button" id="dropdownMenuLink" data-toggle="dropdown" style={{ textDecoration: 'none', marginRight:"15px"}} aria-haspopup="true" aria-expanded="false">
                  {text_lang[language][3]}
                </NavLink>
                <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">

                <center>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none' }}  to = '/product/1'>{product_lang[language][0]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/2'>{product_lang[language][1]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/3'>{product_lang[language][2]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/6'>{product_lang[language][3]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/7'>{product_lang[language][4]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/8'>{product_lang[language][5]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/9'>{product_lang[language][6]}</NavLink>
                </center>
                </div>
              </div>
          </li>
          <li>
            <NavLink onClick={() => ScollToTop()} exact to='/contact' className="link-style" activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][4]}</NavLink>
          </li>
          <li>
          <button onClick={() => changeLanguage("en")} style={{background:"transparent", border:"none"}}><img src={process.env.PUBLIC_URL + '/image/uk.png'} alt="uk" style={{width:"20px", height:"20px"}}/> {text_lang[language][5]}</button>
          <button onClick={() => changeLanguage("th")} style={{background:"transparent", border:"none"}}> <img src={process.env.PUBLIC_URL + '/image/thailand.png'} alt="th" style={{width:"20px", height:"20px"}}/> {text_lang[language][6]}</button>
          </li>
        </ul>
      </div>
    </nav>
    </MediaQuery>




    <MediaQuery maxDeviceWidth={991.99}>
    <div className="container body">
    <nav class="navbar navbar-expand-lg navbar-light top-bar fixed-top" style={{width:"100%"}}>&emsp;
      <NavLink onClick={() => ScollToTop()} exact to='/' style={{color:"black", textDecoration: 'none'}}><img src={process.env.PUBLIC_URL+"/image/logo.png"}
      alt="GSI Logo" style={{width:"30%", marginLeft:"-25%"}}/></NavLink>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" data-toggle="collapse" data-target=".navbar-collapse.show" style={{marginLeft:"2%"}}>
        <ul class="navbar-nav ml-auto">
        <li>
            <NavLink onClick={() => ScollToTop()} exact to='/'className="link-style"activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][1]}</NavLink>
          </li>
          <li>
            <NavLink onClick={() => ScollToTop()} exact to='/about'className="link-style"activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][2]}</NavLink>
          </li>
          <li >
          <div class="dropdown show">
                <a class="link-style dropdown-toggle"  href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" style={{ textDecoration: 'none', marginRight:"15px"}} aria-haspopup="true" aria-expanded="false">
                  {text_lang[language][3]}
                </a>
                <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none' }}  to = '/product/1'>{product_lang[language][0]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/2'>{product_lang[language][1]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/3'>{product_lang[language][2]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/6'>{product_lang[language][3]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/7'>{product_lang[language][4]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/8'>{product_lang[language][5]}</NavLink><br/>
                <NavLink onClick={() => ScollToTop()} className="link-style" activeClassName="link-style-active" style={{ textDecoration: 'none'}}  to = '/product/9'>{product_lang[language][6]}</NavLink>

                </div>
              </div>
          </li>
          <li>
            <NavLink onClick={() => ScollToTop()} exact to='/contact' className="link-style" activeClassName="link-style-active"style={{ textDecoration: 'none', marginRight:"15px"}}>{text_lang[language][4]}</NavLink>
          </li>
          <li>
          <button onClick={() => changeLanguage("en")} style={{background:"transparent", border:"none"}}><img src={process.env.PUBLIC_URL + '/image/uk.png'} style={{width:"20px", height:"20px"}}/> {text_lang[language][5]}</button>
          <button onClick={() => changeLanguage("th")} style={{background:"transparent", border:"none"}}> <img src={process.env.PUBLIC_URL + '/image/thailand.png'} style={{width:"20px", height:"20px"}}/> {text_lang[language][6]}</button>
          </li>
        </ul>
      </div>
    </nav>
    </div>
    </MediaQuery>
</>

)};
}
export default Navbar
