import React, { Component, useEffect} from 'react'
import MediaQuery from 'react-responsive'
import Aos from "aos";
import "aos/dist/aos.css"
import '../style.css';


import ProductIncatalog from '../components/ProductIncatalog'
const ProductCatalogList = (props)=>{
  let data = props.match.params.id
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
    let language = localStorage.getItem('lang')
    return(
      <>
<div className="container body">
      <div class="row" data-aos="fade-up" style={{paddingTop:"90px"}}>
      <div class="col">
      <center>
      <MediaQuery minDeviceWidth={992}>
      {language === "th"?
      <><p style={{fontSize:"5.5vw", color:"gray"}}>บริการของเรา</p>
      <p style={{fontSize:"3vw"}}>เราทำอะไรบ้าง</p></>:

      <><p style={{fontSize:"5.5vw", color:"gray"}}>OUR SERVICES</p>
      <p style={{fontSize:"3vw"}}>What We Provide</p></>}
      </MediaQuery>
      <MediaQuery maxDeviceWidth={991.99}>
      {language === "th"?
      <><p style={{fontSize:"10vw", color:"gray"}}>บริการของเรา</p>
      <p style={{fontSize:"5vw"}}>เราทำอะไรบ้าง</p></>:

      <><p style={{fontSize:"10vw", color:"gray"}}>OUR SERVICES</p>
      <p style={{fontSize:"5vw"}}>What We Provide</p></>}
      </MediaQuery>

      </center>
      </div>
      </div>
      <ProductIncatalog language={language} id={props.match.params.id}/>

</div>
      </>
    )


}

export default ProductCatalogList;
