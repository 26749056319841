import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style.css';
class Footer extends Component{
  render(){
    let language = localStorage.getItem('lang')
  return (

    <div data-aos="zoom-in" class="container body" style={{marginTop:"30px"}}>

    <center>
    {language === "th"?<>
    <p style={{color:"black"}}>บริษัท กันโน่ ซีสเต็มส์ อินทีเกรชั่น จำกัด</p>
    <p style={{color:"black"}}>76/43,45,47,49 ถนน แจ้งวัฒนะ, แขวง อนุสาวรีย์, เขต บางเขน, กรุงเทพมหานคร 10220</p>
    <p style={{color:"black"}}>โทร : (+66) 2-522-6890-91,02-522-6686 แฟ็กซ์ : (+66) 2-522-6687</p>
    <p style={{color:"black"}}>อีเมล : info@gmail.com</p>
    <p style={{color:"black"}}>©2003 by Gunno Systems Integration.</p>
    </>:
    <>
    <p style={{color:"black"}}>Gunno Systems Integration Co.,Ltd.</p>
    <p style={{color:"black"}}>76/43,45,47,49 Chaengwatthana Rd.,Anusawaree, Bangkhen, Bangkok 10220</p>
    <p style={{color:"black"}}>Tel : (+66) 2-522-6890-91,02-522-6686 Fax : (+66) 2-522-6687</p>
    <p style={{color:"black"}}>E-mail : info@gmail.com</p>
    <p style={{color:"black"}}>©2003 by Gunno Systems Integration.</p>
    </>
  }

    </center>


    </div>
  );
  }
}

export default Footer;
