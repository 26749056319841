import React, { Component, useEffect} from 'react'
import MediaQuery from 'react-responsive'
import Aos from "aos";
import "aos/dist/aos.css"
const About = (props)=>{
  useEffect(() => {
    Aos.init({duration: 1000});
  }, [])
    let language = localStorage.getItem('lang')
    return(
      <div class="container body" data-aos="fade-up" style={{marginTop:"90px"}}>

      <MediaQuery minDeviceWidth={992}>

        <div class="row">
          <div class="col" data-aos="fade-right">

          <img src={process.env.PUBLIC_URL+"/image/contact.jpeg"} alt="Snow" style={{width:"100%", height:"100%"}}/>
          </div>
          <div class="col">
            <p style={{fontSize:"3vw", color:"gray"}}>
            {language === 'th' ?<>เกี่ยวกับเรา</>:<> ABOUT US</>}
            </p>
            <p  style={{fontSize:"1.5vw"}}>
            {language === 'th' ?<>บริษัทกันโน่ ซีสเต็มส์ อินทีเกรชั่น จำกัด ก่อตั้งขึ้นเมื่อปี 2546 ธุรกิจหลักของเราได้แก่ ระบบรักษาความปลอดภัยและโทรคมนาคม
อุปกรณ์ทางทหาร บริการด้านไอทีโซลูชั่นตลอดจนภาพและเสียง เรามุ่งมั่นที่จะให้บริการเทคโนโลยีและโซลูชั่นที่ทันสมัย
เพื่อตอบสนองต่อความต้องการสร้างประสบการณ์และความพึงพอใจสูงสุดแก่ลูกค้า</>:<>Gunno Systems Integration Co.,Ltd. was found in 2003. Our core businesses are security and telecommunication systems, military
            equipment, IT solution service, as well as visual and sound. Dynamically and rapidly changes in technological and digital
             fields are in our concern. We are consistently endeavor to adopt new technologies and IT solutions to provide our clients
              best experience and satisfaction.</>}

            </p>
          </div>
        </div>

      </MediaQuery>
      <MediaQuery maxDeviceWidth={991}>

        <div class="row" data-aos="fade-right">

        <img src={process.env.PUBLIC_URL+"/image/contact.jpeg"} alt="Snow" style={{width:"100%", height:"50vh"}}/>
        </div>
        <div class="row">
        <center>
          <p style={{fontSize:"10vw", color:"gray"}}>
            {language === 'th' ?<>เกี่ยวกับเรา</>:<> ABOUT US</>}
          </p>
          <div class="container" style={{marginTop:"-15px"}}>
          <div class="container">
          <p  style={{fontSize:"5vw"}}>
          {language === 'th' ?<>บริษัทกันโน่ ซีสเต็มส์ อินทีเกรชั่น จำกัด ก่อตั้งขึ้นเมื่อปี 2546 ธุรกิจหลักของเราได้แก่ ระบบรักษาความปลอดภัยและโทรคมนาคม
อุปกรณ์ทางทหาร บริการด้านไอทีโซลูชั่นตลอดจนภาพและเสียง เรามุ่งมั่นที่จะให้บริการเทคโนโลยีและโซลูชั่นที่ทันสมัย
เพื่อตอบสนองต่อความต้องการสร้างประสบการณ์และความพึงพอใจสูงสุดแก่ลูกค้า</>:<>Gunno Systems Integration Co.,Ltd. was found in 2003. Our core businesses are security and telecommunication systems, military
            equipment, IT solution service, as well as visual and sound. Dynamically and rapidly changes in technological and digital
             fields are in our concern. We are consistently endeavor to adopt new technologies and IT solutions to provide our clients
              best experience and satisfaction.</>}
          </p>
          </div>
          </div>
        </center>
      </div>

      </MediaQuery>
      </div>

    )


}


export default About;
